var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"py-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('p',{staticClass:"mb-1 font-weight-bold"},[_vm._v("Обязательные поля")]),_c('validation-provider',{attrs:{"name":"Имя","vid":"name","rules":_vm.validationSchema.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Имя","label-for":"publisher-name"}},[_c('b-form-input',{attrs:{"autocomplete":"off","id":"publisher-name","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":_vm.validationSchema.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"publisher-email"}},[_c('b-form-input',{attrs:{"id":"publisher-email","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Пароль","vid":"password","rules":_vm.validationSchema.password},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Пароль","label-for":"publisher-password"}},[_c('b-form-input',{attrs:{"id":"publisher-password","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Юридическое лицо","vid":"legal_entity","rules":_vm.validationSchema.legal_entity},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Юридическое лицо","label-for":"publisher_legal_entity"}},[_c('b-form-input',{attrs:{"id":"publisher_legal_entity","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.legal_entity),callback:function ($$v) {_vm.$set(_vm.formData, "legal_entity", $$v)},expression:"formData.legal_entity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"ИНН","vid":"inn","rules":_vm.validationSchema.inn},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ИНН","label-for":"publisher_inn"}},[_c('b-form-input',{attrs:{"id":"publisher_inn","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.inn),callback:function ($$v) {_vm.$set(_vm.formData, "inn", $$v)},expression:"formData.inn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('p',{staticClass:"mb-1 font-weight-bold"},[_vm._v("Необязательные поля")]),_c('validation-provider',{attrs:{"name":"Контакты","vid":"contacts","rules":_vm.validationSchema.contacts},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Контакты","label-for":"publisher-contacts"}},[_c('b-form-input',{attrs:{"id":"publisher-contacts","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.contacts),callback:function ($$v) {_vm.$set(_vm.formData, "contacts", $$v)},expression:"formData.contacts"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Сайт","vid":"sites","rules":_vm.validationSchema.sites},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Сайт","label-for":"publisher-sites"}},[_c('b-form-input',{attrs:{"id":"publisher-sites","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.sites),callback:function ($$v) {_vm.$set(_vm.formData, "sites", $$v)},expression:"formData.sites"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"КПП","vid":"kpp","rules":_vm.validationSchema.kpp},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"КПП","label-for":"publisher_kpp"}},[_c('b-form-input',{attrs:{"id":"publisher_kpp","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.kpp),callback:function ($$v) {_vm.$set(_vm.formData, "kpp", $$v)},expression:"formData.kpp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"ОГРН","vid":"ogrn","rules":_vm.validationSchema.ogrn},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ОГРН","label-for":"publisher_ogrn"}},[_c('b-form-input',{attrs:{"id":"publisher_ogrn","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.ogrn),callback:function ($$v) {_vm.$set(_vm.formData, "ogrn", $$v)},expression:"formData.ogrn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Юридический адрес","vid":"business_address","rules":_vm.validationSchema.business_address},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Юридический адрес","label-for":"publisher_business_address"}},[_c('b-form-input',{attrs:{"id":"publisher_business_address","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.business_address),callback:function ($$v) {_vm.$set(_vm.formData, "business_address", $$v)},expression:"formData.business_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Почтовый адрес","vid":"postal_address","rules":_vm.validationSchema.postal_address},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Почтовый адрес","label-for":"publisher_postal_address"}},[_c('b-form-input',{attrs:{"id":"publisher_postal_address","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.postal_address),callback:function ($$v) {_vm.$set(_vm.formData, "postal_address", $$v)},expression:"formData.postal_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Расчетный счет","vid":"checking_account","rules":_vm.validationSchema.checking_account},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Расчетный счет","label-for":"publisher_checking_account"}},[_c('b-form-input',{attrs:{"id":"publisher_checking_account","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.checking_account),callback:function ($$v) {_vm.$set(_vm.formData, "checking_account", $$v)},expression:"formData.checking_account"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Кор. счет","vid":"correspondent_account","rules":_vm.validationSchema.correspondent_account},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Кор. счет","label-for":"publisher_correspondent_account"}},[_c('b-form-input',{attrs:{"id":"publisher_correspondent_account","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.correspondent_account),callback:function ($$v) {_vm.$set(_vm.formData, "correspondent_account", $$v)},expression:"formData.correspondent_account"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Название банка","vid":"bank_name","rules":_vm.validationSchema.bank_name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название банка","label-for":"publisher_bank_name"}},[_c('b-form-input',{attrs:{"id":"publisher_bank_name","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.bank_name),callback:function ($$v) {_vm.$set(_vm.formData, "bank_name", $$v)},expression:"formData.bank_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"БИК банка","vid":"bank_bik","rules":_vm.validationSchema.bank_bik},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"БИК банка","label-for":"publisher_bank_bik"}},[_c('b-form-input',{attrs:{"id":"publisher_bank_bik","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.bank_bik),callback:function ($$v) {_vm.$set(_vm.formData, "bank_bik", $$v)},expression:"formData.bank_bik"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Дата начала сотрудничества","vid":"partnership_started_at","rules":_vm.validationSchema.partnership_started_at},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Дата начала сотрудничества","label-for":"publisher_partnership_started_at"}},[_c('b-form-datepicker',{attrs:{"id":"publisher_partnership_started_at","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.partnership_started_at),callback:function ($$v) {_vm.$set(_vm.formData, "partnership_started_at", $$v)},expression:"formData.partnership_started_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Номер договора","vid":"number_of_contract","rules":_vm.validationSchema.number_of_contract},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Номер договора","label-for":"publisher_number_of_contract"}},[_c('b-form-input',{attrs:{"id":"publisher_number_of_contract","state":errors.length > 0 ? false : null},model:{value:(_vm.formData.number_of_contract),callback:function ($$v) {_vm.$set(_vm.formData, "number_of_contract", $$v)},expression:"formData.number_of_contract"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),([1].includes(_vm.roleId))?_c('validation-provider',{attrs:{"name":"Менеджер","vid":"manager_id","rules":_vm.validationSchema.manager_id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Менеджер (обязательно)","label-for":"publisher_manager_id"}},[_c('b-form-select',{attrs:{"id":"publisher_manager_id","options":_vm.managers,"state":errors.length > 0 ? false : null},model:{value:(_vm.formData.manager_id),callback:function ($$v) {_vm.$set(_vm.formData, "manager_id", $$v)},expression:"formData.manager_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}):_vm._e(),_c('loader-button',{attrs:{"type":"submit","variant":"primary","disabled":_vm.isSubmitting,"is-submitting":_vm.isSubmitting}},[_vm._v(" "+_vm._s(_vm.user.id ? 'Сохранить' : 'Создать')+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }