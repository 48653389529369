<template>
  <validation-observer ref="form" v-slot="{ invalid }">
    <b-form class="py-2" @submit.prevent="handleSubmit">
      <p class="mb-1 font-weight-bold">Обязательные поля</p>
      <validation-provider v-slot="{ errors }" name="Имя" vid="name" :rules="validationSchema.name">
        <b-form-group label="Имя" label-for="publisher-name">
          <b-form-input autocomplete="off" id="publisher-name" v-model="formData.name" :state="errors.length > 0 ? false : null" />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="Email" vid="email" :rules="validationSchema.email">
        <b-form-group label="Email" label-for="publisher-email">
          <b-form-input id="publisher-email" v-model="formData.email" :state="errors.length > 0 ? false : null" />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="Пароль" vid="password" :rules="validationSchema.password">
        <b-form-group label="Пароль" label-for="publisher-password">
          <b-form-input id="publisher-password" v-model="formData.password" :state="errors.length > 0 ? false : null" />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider
          v-slot="{ errors }"
          name="Юридическое лицо"
          vid="legal_entity"
          :rules="validationSchema.legal_entity"
      >
        <b-form-group label="Юридическое лицо" label-for="publisher_legal_entity">
          <b-form-input
              id="publisher_legal_entity"
              v-model="formData.legal_entity"
              :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="ИНН" vid="inn" :rules="validationSchema.inn">
        <b-form-group label="ИНН" label-for="publisher_inn">
          <b-form-input id="publisher_inn" v-model="formData.inn" :state="errors.length > 0 ? false : null" />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <p class="mb-1 font-weight-bold">Необязательные поля</p>

      <validation-provider v-slot="{ errors }" name="Контакты" vid="contacts" :rules="validationSchema.contacts">
        <b-form-group label="Контакты" label-for="publisher-contacts">
          <b-form-input id="publisher-contacts" v-model="formData.contacts" :state="errors.length > 0 ? false : null" />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="Сайт" vid="sites" :rules="validationSchema.sites">
        <b-form-group label="Сайт" label-for="publisher-sites">
          <b-form-input id="publisher-sites" v-model="formData.sites" :state="errors.length > 0 ? false : null" />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="КПП" vid="kpp" :rules="validationSchema.kpp">
        <b-form-group label="КПП" label-for="publisher_kpp">
          <b-form-input id="publisher_kpp" v-model="formData.kpp" :state="errors.length > 0 ? false : null" />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="ОГРН" vid="ogrn" :rules="validationSchema.ogrn">
        <b-form-group label="ОГРН" label-for="publisher_ogrn">
          <b-form-input id="publisher_ogrn" v-model="formData.ogrn" :state="errors.length > 0 ? false : null" />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Юридический адрес"
        vid="business_address"
        :rules="validationSchema.business_address"
      >
        <b-form-group label="Юридический адрес" label-for="publisher_business_address">
          <b-form-input
            id="publisher_business_address"
            v-model="formData.business_address"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Почтовый адрес"
        vid="postal_address"
        :rules="validationSchema.postal_address"
      >
        <b-form-group label="Почтовый адрес" label-for="publisher_postal_address">
          <b-form-input
            id="publisher_postal_address"
            v-model="formData.postal_address"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Расчетный счет"
        vid="checking_account"
        :rules="validationSchema.checking_account"
      >
        <b-form-group label="Расчетный счет" label-for="publisher_checking_account">
          <b-form-input
            id="publisher_checking_account"
            v-model="formData.checking_account"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Кор. счет"
        vid="correspondent_account"
        :rules="validationSchema.correspondent_account"
      >
        <b-form-group label="Кор. счет" label-for="publisher_correspondent_account">
          <b-form-input
            id="publisher_correspondent_account"
            v-model="formData.correspondent_account"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Название банка"
        vid="bank_name"
        :rules="validationSchema.bank_name"
      >
        <b-form-group label="Название банка" label-for="publisher_bank_name">
          <b-form-input
            id="publisher_bank_name"
            v-model="formData.bank_name"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider v-slot="{ errors }" name="БИК банка" vid="bank_bik" :rules="validationSchema.bank_bik">
        <b-form-group label="БИК банка" label-for="publisher_bank_bik">
          <b-form-input id="publisher_bank_bik" v-model="formData.bank_bik" :state="errors.length > 0 ? false : null" />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Дата начала сотрудничества"
        vid="partnership_started_at"
        :rules="validationSchema.partnership_started_at"
      >
        <b-form-group label="Дата начала сотрудничества" label-for="publisher_partnership_started_at">
          <b-form-datepicker
            id="publisher_partnership_started_at"
            v-model="formData.partnership_started_at"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider
        v-slot="{ errors }"
        name="Номер договора"
        vid="number_of_contract"
        :rules="validationSchema.number_of_contract"
      >
        <b-form-group label="Номер договора" label-for="publisher_number_of_contract">
          <b-form-input
            id="publisher_number_of_contract"
            v-model="formData.number_of_contract"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <validation-provider v-if="[1].includes(roleId)" v-slot="{ errors }" name="Менеджер" vid="manager_id" :rules="validationSchema.manager_id">
        <b-form-group label="Менеджер (обязательно)" label-for="publisher_manager_id">
          <b-form-select
            id="publisher_manager_id"
            v-model="formData.manager_id"
            :options="managers"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>

      <loader-button type="submit" variant="primary" :disabled="isSubmitting" :is-submitting="isSubmitting">
        {{ user.id ? 'Сохранить' : 'Создать' }}
      </loader-button>
    </b-form>
  </validation-observer>
</template>

<script>
import { BForm, BFormSelect, BFormGroup, BFormInput, BButton, BFormDatepicker } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import LoaderButton from '@/components/LoaderButton'
import { required, email, min, length, max } from '@validations'

export default {
  components: {
    BForm,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BButton,
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    LoaderButton
  },
  props: {
    isSubmitting: {
      type: Boolean,
      required: false,
      default: () => false
    },
    validationSchema: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: false,
      default: () => ({})
    },
    data: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      formData: {
        name: this.user.name ?? '',
        email: this.user.email ?? '',
        password: this.user.password ?? '',
        contacts: this.user.contacts ?? '',
        sites: this.user.sites ?? '',
        legal_entity: this.user.legal_entity ?? '',
        inn: this.user.inn ?? '',
        kpp: this.user.kpp ?? '',
        ogrn: this.user.ogrn ?? '',
        business_address: this.user.business_address ?? '',
        postal_address: this.user.postal_address ?? '',
        checking_account: this.user.checking_account ?? '',
        correspondent_account: this.user.correspondent_account ?? '',
        bank_name: this.user.bank_name ?? '',
        bank_bik: this.user.bank_bik ?? '',
        partnership_started_at: this.user.partnership_started_at ?? '',
        number_of_contract: this.user.number_of_contract ?? '',
        manager_id: this.user.manager?.id ?? null,
        is_active: this.user.is_active ?? true
      },
      roleId: null,
      managers: [],

      // Validations
      email,
      min,
      length,
      required,
      max
    }
  },
  methods: {
   async handleSubmit() {
      const isValid = await this.$refs.form.validate();

      if(isValid) {
        const submitData = {...this.formData}
        if (this.user.id) submitData.id = this.user.id
        this.$emit('submit', {...submitData})
      }
    }
  },
  created() {
    this.roleId = parseInt(localStorage.getItem('userRoleId'), 10)
    const partnerTypeList = this.data.type === 'publisher' ? '/api/lists/manager' : '/api/lists/manager_dsp';

    if ([1,2].includes(this.roleId)) {
      this.$http.get(partnerTypeList).then((response) => {
        const {data} = response.data
        this.managers = data.map((manager) => ({
          value: manager.id,
          text: manager.name
        }))
      })
    }
  }
}
</script>
